import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Libraries
import noUiSlider from 'nouislider'
import ReactRailsUJS from 'react_ujs'

const componentRequireContext = require.context('components', true)

ReactRailsUJS.useContext(componentRequireContext)

window.noUiSlider = noUiSlider
